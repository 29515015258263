<template>
  <v-dialog
    v-model="display"
    width="800"
    persistent
    v-if="column && column"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-edit-column-dialog>
      <DialogTitle @close="$emit('close')" title="Configure this Column" />
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              dense
              name="label"
              label="Label"
              v-model="column.label"
              clearable
              hint="Title of the column"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <FieldDesigner
              :key="keyIncrement"
              :definition.sync="column"
              :context="context"
              container="list"
            />
          </v-col>
          <v-col cols="12" v-if="isList">
            <v-switch
              dense
              aut-edit-field-bulk-operation
              name="bulk_operation"
              v-model="column.bulk_operation"
              label="Bulk Operation"
              persistent-hint
              hint="This field can be updated via a bulk update operation"
            ></v-switch>
          </v-col>
          <v-col>
            <v-slider
              v-model="columnWidth"
              class="align-center"
              label="Column Width"
              dense
            >
              <template v-slot:append>
                {{ columnWidth }}
              </template>
            </v-slider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-edit-column-cancel>
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="updateColumn"
          aut-action-edit-column-update
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogMixin } from "@/components/mixin.js";
import { clone } from "@/util.js";
import FieldDesigner from "@/components/fields/Field/FieldDesigner.vue";

const debug = require("debug")("atman.components.edit_column_dialog"); // eslint-disable-line

export default {
  name: "EditColumnDialog",
  mixins: [dialogMixin],
  props: {
    definition: {
      type: Object,
    },
    context: {
      type: String,
    },
    isList: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      column: clone(this.definition),
      display: true,
      keyIncrement: 1,
    };
  },
  components: {
    FieldDesigner,
  },
  computed: {
    columnWidth: {
      get() {
        const width = this.column?.display?.width || "auto";
        return width == "auto" ? 1 : width;
      },
      set(width) {
        this.column.display.width = width;
      },
    },
  },
  mounted() {
    debug(`In mounted of EditColumnDialog`, this.column);
  },
  methods: {
    updateColumn() {
      debug(`updated column:`, this.column);
      this.$emit("update:definition", this.column);
      this.$emit("close");
    },
  },
};
</script>
<style lang="css"></style>
